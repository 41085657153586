<template>
  <div class="router-error-404-catchall">
    <titleBar
      :title="'Pricing'"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="section">
      <div class="container text-align-center">
        <!--  
        <h1 class="center title is-3"><span class="">Subscribe</span></h1>
        <p>Simple pricing. Cancel anytime.</p>

        -->

        <!--    <p>
     
            
            Full access to all chatbots and tools.Support AI creators. Generous limits.

          Cancel anytime.
        </p>
        <br />
-->
        <!--
          invitaion.app gmail
          https://dashboard.stripe.com/test/pricing-tables/prctbl_1NmcfsE7mb2AoEkxRtIau5vk

        -->
        <br />
        <stripe-pricing-table
          :customer-email="customerEmail"
          :client-reference-id="CLIENT_REFERENCE_ID"
          pricing-table-id="prctbl_1NmcfsE7mb2AoEkxRtIau5vk"
          publishable-key="pk_test_51NmcUrE7mb2AoEkx6CACRkj23pzfQ3OJXLV4JEQAwWVvYYT0WB5PnAmsesUyr8axEnnNPm7FlioEkmMWgUH4d2e500gNipUJ1D"
        >
        </stripe-pricing-table>

        <!--  All profits are shared with AI creators
       
        {{ CLIENT_REFERENCE_ID }}

        {{ customerEmail }} == customerEmail
         -->

        <!-- https://dashboard.stripe.com/test/pricing-tables/prctbl_1Mku51EVhHXcXUfCHiMhPfur
        
     
          NOT SURE WHAT IS THIS ACCOINt- kicking maybe
        <stripe-pricing-table
          :customer-email="customerEmail"
          :client-reference-id="CLIENT_REFERENCE_ID"
          pricing-table-id="prctbl_1Mku51EVhHXcXUfCHiMhPfur"
          publishable-key="pk_test_51MktsjEVhHXcXUfCq6Pj9wxsabEMIHEWqVAkGE3DwAlNkyXWcIs3w45SGTVO3FCJjDQ0bVWw9WVx1esSkmh5x2wK00xqPNorlZ"
        >
        </stripe-pricing-table>

           -->
      </div>
    </div>
    <!-- 
    <empty title="Join Discord" hrefTo="https://discord.gg/kYKdsTvheA" btLabel="View Discord Server" />
   -->
    <!--
      <h1>This is an 404 page - not found...</h1>
  
  
          <h1>This is an 404 page - not found...</h1>
              <h1>This is an 404 page - not found...</h1>
                  <h1>This is an 404 page - not found...</h1>
                      <h1>This is an 404 page - not found...</h1>
  
                    -->
  </div>
</template>
<script>
//import e404 from "@/components/e/e404";

const STRIPE_EMBED = "https://js.stripe.com/v3/pricing-table.js";
export default {
  data() {
    return {
      transitionName: "fade", //default
    };
  },
  components: {
    //   e404,
    //  Footer: Footer
  },
  computed: {
    CLIENT_REFERENCE_ID() {
      return this.$store.main.getters.userId;
    },
    customerEmail() {
      return this.$store.main.getters.profile?.email;
    },
  },
  methods: {
    loadStripeEmbed() {
      const script = document.createElement("script");
      script.src = STRIPE_EMBED;
      script.async = true;
      document.body.appendChild(script);
    },
  },
  mounted() {
    this.loadStripeEmbed();
  },
};
</script>
